<template>
  <q-carousel
    animated
    v-model="slide"
    arrows
    navigation
    infinite
    control-color="white"
    control-text-color="primary"
    height="100%"
    class="text-primary"
  >
    <q-carousel-slide :name="1" @click="onClickBanner1()">
      <q-img class="full-height" contain :src="require('@/assets/images/banner_1.png')" />
    </q-carousel-slide>
    <q-carousel-slide :name="2" @click="onClickBanner2()">
      <q-img class="full-height cursor-pointer" contain :src="require('@/assets/images/banner_2.jpg')" />
    </q-carousel-slide>
    <q-carousel-slide :name="3" @click="onClickBanner3()">
      <q-img class="full-height cursor-pointer" contain :src="require('@/assets/images/banner_3.jpg')" />
    </q-carousel-slide>
    <q-carousel-slide :name="4" @click="onClickBanner4()">
      <q-img
        v-if="new Date() <= new Date('2024-01-01')"
        class="full-height"
        contain
        :src="require('@/assets/images/banner_4-dezembro.jpg')"
      />
      <q-img v-else class="full-height" contain :src="require('@/assets/images/banner_4.png')" />
    </q-carousel-slide>
  </q-carousel>
</template>

<script>
export default {
  props: {
    data: Object
  },
  data() {
    return {
      slide: 1
    };
  },
  methods: {
    onClickBanner1() {},
    onClickBanner2() {
      window.open(
        'https://afilio-public-general.s3.amazonaws.com/2022/institucional/manuais/manual_plataforma_v3.pdf',
        '_blank'
      );
    },
    onClickBanner3() {
      window.open('https://api.whatsapp.com/send?phone=5511996935088', '_blank');
    },
    onClickBanner4() {}
  }
};
</script>
